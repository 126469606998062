@import '../../variables.scss';

.hamburger {
  position: absolute;
  top: 30px;
  right: 5px;
  width: 20px;
  height: 15px;
  cursor: pointer;

  border: 0;
  background-color: transparent;
  z-index: 1000;
  display: none;

  &-line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    left: 0;
    margin: auto;
    transition: all 0.3s;

    &.top {
      top: 0;
    }

    &.center {
      top: 0;
      bottom: 0;
      width: 100%;
    }

    &.bottom {
      bottom: 0;
    }
  }

  &.active {
    .hamburger-line {
      &.top {
        bottom: 0;
        transform: rotate(45deg);
      }

      &.center {
        width: 0;
        opacity: 0;
      }

      &.bottom {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
}

@media (max-width: 1024px) {
  .hamburger {
    display: inline-block;
  }
}
