.filemanager- {
  &container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: .4;
  }

  &popup {
    width: 900px;
    max-width: calc(100% - 20px);
    position: relative;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 5px;
    box-shadow: 0 3px 9px rgb(0, 0, 0);
    background-clip: padding-box;
    outline: 0;
    padding: 10px;
    max-height: 100vh;
    overflow: auto;

    .btn-close {
      padding: 10px;
      height: auto;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  &content {
    display: flex;
    flex-wrap: wrap;
  }

  &navbar {
    span {
      cursor: pointer;
      margin-right: 2px;
    }

    .button-group {
      margin-top: 10px;

      .btn {
        padding: 5px;
        border-radius: 4px;
        border: 1px solid gainsboro;
        height: auto;
        margin-right: 5px;
      }
    }

    .create-folder {
      padding: 10px;
      position: absolute;
      height: 200px;
      z-index: 2;
      width: 300px;
      background: #FFF;
      left: calc(50% - 150px);
      top: calc(50% - 100px);
      border-radius: 5px;
      border: 1px solid gainsboro;

      .btn {
        float: right;
        height: auto;
        margin-top: 10px;
        margin-left: auto;
      }
    }
  }

  &item {
    width: 122px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;

    &:hover {
      border-color: gainsboro;
    }

    .item-checkbox {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 4px;
      background: #FFF;
    }

    .item {
      width: 100px;
      height: 100px;
    }

    .name {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}
