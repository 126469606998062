@import '../../variables.scss';

.aside {
  position: sticky;
  padding: 20px 0 40px 0;
  top: 0;
  width: 255px;
  height: 100vh;
  background: #262626;
  overflow: auto;

  .auth-user {
    text-align: center;
    color: #FFF;
    border-top: 1px solid #343434;
    border-bottom: 1px solid #343434;
  }

  .companies-select {
    .react-select__control {
      background-color: transparent;
      border: 0 !important;
      box-shadow: none !important;

      .react-select__single-value {
        color: #FFFFFF;
      }

      .react-select__indicator-separator {
        display: none;
      }
    }

    .react-select__menu {
      width: calc(100% - 20px);
      margin-left: 10px;
      z-index: 999;
      background-color: #262626;

      .react-select__option {
        color: #FFFFFF;

        &--is-focused {
          background-color: #3a3a3a;
        }
      }
    }
  }
}

.logo {
  width: 117px;
  margin: 0 auto 20px;
}

.menu {
  .accordion-item {
    border: none;
    background-color: #262626;

    &:first-child {
      padding: 10px 0;
    }
  }

  .accordion-button {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #323232;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    text-decoration: none;
    border-bottom: 1px solid #262626;

    &::after {
      background-image: url('../../images/arrow.svg');
      background-position: center;
    }

    &:not(.collapsed) {
      box-shadow: none;
    }

    &:hover {
      .icon {
        margin-right: 20px;
      }
    }

    a {
      display: flex;
      align-items: center;
      background: #323232;
      font-size: 15px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: #FFFFFF;
      text-decoration: underline #fff;
      margin-right: 40px;
    }
  }

  .icon {
    background: #1E1E1E;
    border-radius: 5px;
    margin-right: 10px;
    min-width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    img {
      width: 10px;
      height: 10px;
    }
  }
}

.accordion {
  padding-left: 0;
  list-style: none;

  &-body {
    padding: 0;

    a {
      padding: 14px 20px;
      background: #151515;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      text-decoration: none;
      margin-bottom: 1px;
      transition: all 0.3s;

      &:hover {
        padding-left: 30px;
      }
    }

  }
}

.hide-arrow {
  .accordion-button::after {
    background: none !important;
  }
}


@media (max-width: 1024px) {

  .aside {
    position: fixed;
    top: 0;
    left: calc(-100% + 30px);
    padding: 40px 50px 0;
    width: 100%;
    height: 100%;
    background: #262626;
    z-index: 1000;
    transition: all 0.3s;

    &.open {
      padding: 40px 0 0;
      left: 0;

      .hamburger {
        right: 12px;
      }
    }
  }
}

@media (max-width: 768px) {

}

@media (max-width: 578px) {

}

@media (max-width: 480px) {

}
