.statement {
  position: relative;
  padding: 15px;
  border: 1px solid rgba(207, 217, 224, 1);
  background-color: rgba(246, 248, 250, 1);
  border-radius: 4px;
  margin-bottom: 15px;

  &__head {
    padding-left: 25px;
    display: flex;
    align-items: center;
  }

  .tag {
    height: 20px;
    display: inline-flex;
    align-items: center;
    padding: 3px 10px;
    border: none;
    border-radius: 8px;
    font-size: 12px;
    color: #FFFFFF;
    margin-right: 10px;
    line-height: 1;

    &.green {
      background-color: rgba(123, 188, 149, 1);
    }

    &.red {
      background-color: rgba(200, 104, 83, 1);
    }
  }

  &__body {
    padding-left: 25px;
    display: flex;
    align-items: center;

    .btn {
      background-color: rgba(238, 242, 244, 1);
      border: 1px solid rgba(235, 238, 239, 1);
      border-radius: 4px;
      font-size: 14px;
      color: #3B5E7A;
      margin: 0 5px;

      &:active {
        transform: scale(1);
      }

      &.active {
        border-color: rgba(52, 121, 207, 1);
        background-color: rgba(71, 122, 204, 0.117647058823529);
        color: #3B5E7A;
      }

      &:hover {
        border-color: rgba(52, 121, 207, 1);
        background-color: rgba(71, 122, 204, 0.117647058823529);
        color: #3B5E7A;
      }
    }
  }

  &__button {
    text-align: right;

    .btn {
      margin-bottom: 5px;
      line-height: 1;
    }
  }

  &__drop-body {
    //padding-top: 40px;
    h6 {
      position: absolute;
      top: -70px;
      right: 0;
    }
  }

  &.open {
    .statement__drop-open {
      transform: rotate(180deg);
    }
  }

  &.excluded {
    .tag, .statement__button {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 480px) {
  .statement {
    &__head {
      flex-wrap: wrap;
    }

    &__text {
      margin-bottom: 10px;
    }

    &__body {
      flex-wrap: wrap;
    }
  }
}


.table-statement {
  width: 100%;
  display: table;
  border-collapse: separate;
  border-spacing: 0;

  .product-name {
    width: 60%;
    min-width: 350px;
  }

  .sum {
    border-radius: 4px;
    border: 1px solid rgba(188, 202, 213, 1);
    width: 65px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .w98 {
    width: 98px;
    max-width: 98px;
    min-width: 98px;
  }

  .w250 {
    width: 250px;
    min-width: 250px;
  }

  .solid-left {
    border-left-style: solid;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;

    th {
      border-top-style: solid;

      &:first-child {
        border-left-style: solid;
      }
    }
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;

  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border: 0;
  }

  td, th {
    padding: 0;
    background: #fff;
    height: auto;
    width: auto;
    border: 1px solid rgb(207, 217, 224);
    border-style: none solid solid none;

    &:first-child {
      min-width: auto;
      width: 65px;
    }

    &:last-child {
      //width: 147px;
    }
  }

  th {
    font-size: 12px;
    text-align: center;
    height: 50px;
    line-height: 1;
    color: rgb(63, 97, 124);

    //&:nth-child(3) {
    //  .th {
    //    justify-content: flex-start;
    //    text-align: left;
    //  }
    //}
    //&:nth-child(4) {
    //  .th {
    //    justify-content: flex-start;
    //    text-align: left;
    //  }
    //}
    //&:nth-child(5) {
    //  .th {
    //    justify-content: flex-start;
    //    text-align: left;
    //  }
    //}

  }

  .th {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .th, .td {
    padding: 12px;

    //margin: -1px;
  }
}


.statement__drop-open {
  position: absolute;
  top: 21px;
  left: 15px;
  font-size: 22px;
  cursor: pointer;
}
