
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  color: $gray-900;
  font-family: $fontGilroy-400;

}

html, body {
  min-height: 100%;
  display: flex;
  width: 100%;
}

div#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden;
}

.text-orange {
  color: $orange;
}

.text-gray {
  color: $gray-500;
}

.text-green {
  color: $green;
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $gray-900;
}

.abs-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.copy-button {
  right: 3px;
  top: 5px;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz19 {
  font-size: 19px;
}

.fz20 {
  font-size: 20px;
}

.fz21 {
  font-size: 21px;
}

.fz22 {
  font-size: 22px;
}

.fz24 {
  font-size: 24px;
}

.fz26 {
  font-size: 26px;
  line-height: 1;
}

.fz28 {
  font-size: 28px;
  line-height: 1;
}

.fz36 {
  font-size: 36px;
  line-height: 1;
}

h1, .h1 {
  font-size: 36px;
  letter-spacing: 0.03em;
  color: #2A2A2A;
  font-family: $fontGilroy-700;
}

h2, .h2 {
  font-size: 42px;
}

h3, .h3 {
  font-size: 36px;
}

h4, .h4 {
  font-size: 28px;
}

h5, .h5 {
  font-size: 24px;
}

.fw-Gilroy-100 {
  font-family: $fontGilroy-100;
}

.fw-Gilroy-300 {
  font-family: $fontGilroy-300;
}

.fw-Gilroy-400 {
  font-family: $fontGilroy-400;
}

.fw-Gilroy-500 {
  font-family: $fontGilroy-500;
}

.fw-Gilroy-600 {
  font-family: $fontGilroy-600;
}

.fw-Gilroy-700 {
  font-family: $fontGilroy-700;
}

.fw-Gilroy-800 {
  font-family: $fontGilroy-800;
}

.fw-Gilroy-900 {
  font-family: $fontGilroy-900;
}


@for $i from 1 through 10 {
  $s: $i * 5;

  .m-#{$s} {
    margin: $s + px;
  }
  .mt-#{$s} {
    margin-top: $s + px;
  }
  .mb-#{$s} {
    margin-bottom: $s + px;
  }
  .ml-#{$s} {
    margin-left: $s + px;
  }
  .mr-#{$s} {
    margin-right: $s + px;
  }

  .p-#{$s} {
    padding: $s + px;
  }
  .pt-#{$s} {
    padding-top: $s + px;
  }
  .pb-#{$s} {
    padding-bottom: $s + px;
  }
  .pl-#{$s} {
    padding-left: $s + px;
  }
  .pr-#{$s} {
    padding-right: $s + px;
  }
}


.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@media (min-width: 992px) and (max-width: 1200px) {
  .d-lg-contents {
    display: contents;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .d-md-contents {
    display: contents;
  }
}

.form-control {
  height: 50px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  //padding: 0 40px 0 0;
  border-radius: 5px;
  letter-spacing: 0.03em;

  &.default {
    height: 50px;
  }

  .icon {
    width: 70px;
    height: 100%;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 20px;
      max-height: 20px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;

    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  &:active {
    outline: none;
    box-shadow: none;
    transform: scale(0.96);
  }

  &.btn-lg {
    height: 85px;
    font-size: 18px;
    border-radius: 10px;
  }

  &.btn-sm {
    height: 46px;
    font-size: 14px;
  }

  &.btn-dark {
    background: #2B2B2B;
    color: #FFFFFF;

    .icon {
      background: #161616;
    }
  }

  &.btn-orange {
    background: #F56126;
    color: #FFFFFF;

    .icon {
      background: #CE460F;
    }
  }


  &.btn-success {
    border-color: transparent;
    background-color: $green;
    color: #104434;
    font-family: $fontGilroy-700;

    &:hover {
      background-color: $green;
      color: #104434;
    }
  }

  &.btn-outline-success {
    border-color: $green;
    color: $green;
    font-family: $fontGilroy-700;

    &:hover {
      background-color: transparent;
      color: #104434;
    }
  }

}

button {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.app-admin {
  .page-body {
    width: calc(100% - 255px);
    padding: 30px;
    position: relative;
  }
}

.react-select .react-select__menu {
  z-index: 11;
}

.react-select, .react-select__control {
  width: 100%;
  min-height: 50px !important;
}

.MuiDateRangeCalendar-root > div:first-child {
  display: none !important;
  opacity: 0 !important;
}

fieldset {
  border-color: hsl(0, 0%, 80%) !important;
}

.css-13cymwt-control, .css-13cymwt-control:hover, .css-t3ipsp-control, .css-t3ipsp-control:hover {
  min-height: 50px !important;
}


.letter {
  letter-spacing: 0.03em;
}

.text-orange {
  color: #F56126;
}

.cursor-pointer, .pointer {
  cursor: pointer;
}

.field-bg {
  height: 50px;
  background: #F3F4F6 !important;
  border-radius: 10px !important;
  border: none !important;
}

.l-s {
  letter-spacing: 0.03em;
}


@media (max-width: 1024px) {
  .app-admin {
    .page-body {
      padding-left: 60px;
      width: 100%;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: #2B2B2B;
      }
    }
  }
}

@media (max-width: 578px) {
  h1, .h1 {
    font-size: 24px;
  }
  .fz36 {
    font-size: 26px;
  }
  .page-body {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .app-admin {
    .page-body {
      padding-left: 40px;
      padding-right: 15px;
      width: 100%;
    }
  }
}

.MuiTextField-root, .MuiInputBase-formControl {
  height: 50px;

  label {
    top: -2px;
  }
}

.bg-gainsboro {
  background: gainsboro;
}


.custom-modal {
  position: fixed !important;
  top: 0;
  right: 0;
  background: #FFF;
  height: 100vh;
  z-index: 200;
  overflow: auto;

  & > button {
    float: left;
    margin-right: 10px;
    border: 0;
    padding: 0;
    background: 0;
    color: #000;
    height: 45px;

    svg {
      width: 36px;
      height: 36px;
    }
  }
}


@for $i from 1 through 10 {
  $s: $i * 10;

  .m-#{$s} {
    margin: $s + px !important;
  }
  .mt-#{$s} {
    margin-top: $s + px !important;
  }
  .mb-#{$s} {
    margin-bottom: $s + px !important;
  }
  .ml-#{$s} {
    margin-left: $s + px !important;
  }
  .mr-#{$s} {
    margin-right: $s + px !important;
  }

  .p-#{$s} {
    padding: $s + px !important;
  }
  .pt-#{$s} {
    padding-top: $s + px !important;
  }
  .pb-#{$s} {
    padding-bottom: $s + px !important;
  }
  .pl-#{$s} {
    padding-left: $s + px !important;
  }
  .pr-#{$s} {
    padding-right: $s + px !important;
  }
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.w-100-p {
  width: 100%;
}

.text-right {
  text-align: right !important;
}

.warning {
  background-color: #fcb9b9 !important;
}

.danger {
  background-color: #ff8888 !important;
}

.red {
  color: red !important;
}

.required {
  font-size: 24px;
  line-height: 20px;
}

.font-weight-bold {
  font-weight: bold;
}
