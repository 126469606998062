@import '../../variables.scss';

.table-scroll {
  overflow: auto;
  width: 100%;
  display: flex;
  position: relative;
}

.table {

}

.table-striped {

}

.table-bordered {

}


.tableGlobal {
  //width: 100%;
  //min-width: 1000px;
  th {
    font-weight: 400;
    font-size: 16px;
    height: 45px;
    text-align: center;
    padding: 5px;
    color: #727272;
  }

  tr {
    position: relative;

    td {
      height: 76px;
    }

    th, td {
      min-width: 125px;
      text-align: center;
      border-bottom: 1px solid #D5D5D5;
      border-right: 1px solid #D5D5D5;

      &:first-child {
        text-align: left;
        padding-left: 0;
      }

      &:last-child {
        text-align: right;
        padding-right: 0;
        border-right: 0;
      }
    }

  }
}


@media (max-width: 1024px) {
  .tableScheduleEdit {
    .px-5 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .ps-5 {
      padding-left: 20px !important;
    }

    .pe-5 {
      padding-right: 20px !important;
    }
  }

}

@media (max-width: 768px) {

}

@media (max-width: 578px) {

  .tableGlobal {
    min-width: auto;

    thead {
      display: none;
    }

    tbody {
      display: block;
    }

    tr {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      border-left: 1px solid #D5D5D5;
      border-top: 1px solid #D5D5D5;

      td {
        position: relative;
        padding: 10px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::before {
          content: attr(data-th);
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          color: #727272;
        }

        &:first-child {
          text-align: right;
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
          border-right: 1px solid #D5D5D5;
        }
      }
    }
  }
  .tableScheduleEdit {
    .px-5 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .ps-5 {
      padding-left: 10px !important;
    }

    .pe-5 {
      padding-right: 10px !important;
    }
  }

}

@media (max-width: 480px) {
  .tableScheduleEdit {
    &.table-scroll {
      overflow: visible;
    }

    tr {
      border: none;

      td {
        border: none;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &:first-child {
          text-align: right;
          padding-left: 0;
        }

        &:last-child {
          text-align: right;
          padding-right: 0;
          border: none;
        }
      }
    }

    .py-3 {
      padding: 0 !important;
    }

    .px-5 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .ps-5 {
      padding-left: 0 !important;
    }

    .pe-5 {
      padding-right: 0 !important;
    }
  }
}
