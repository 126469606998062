$fontGilroy-100: "Gilroy-Thin", sans-serif;
$fontGilroy-300: "Gilroy-UltraLight", sans-serif;
$fontGilroy-400: "Gilroy-Regular", sans-serif;
$fontGilroy-500: "Gilroy-Medium", sans-serif;
$fontGilroy-600: "Gilroy-SemiBold", sans-serif;
$fontGilroy-700: "Gilroy-Bold", sans-serif;
$fontGilroy-800: "Gilroy-Heavy", sans-serif;
$fontGilroy-900: "Gilroy-Black", sans-serif;


// color

$white: #fff !default;
$gray-100: #F9F9F9 !default;
$gray-200: #e9ecef !default;
$gray-300: #ddd !default;
$gray-300: #ddd !default;
$gray-400: #C4C4C4; //
$gray-500: #9A9A9A; //
$gray-600: #6c757d !default;

$gray-700: #4E4E4E; //
$gray-800: #333333 !default;
$gray-900: #2A2A2A; // используется
$black: #000 !default;

$blue: #32A8D7; //
$blue: #0D9BE1; //
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;

$pink: #d63384 !default;
$red: #E35353; //
$orange: #F56126; //
$yellow: #ffc107 !default;
$green: #31D6A5; //
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;


@mixin background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin image-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
