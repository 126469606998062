@font-face {
  font-family: 'Gilroy-Black';
  src: url('../fonts/gilroy-black.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-black.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-black.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-black.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-black.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-black.svg#gilroy-black') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 900;
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: url('../fonts/gilroy-heavy.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-heavy.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-heavy.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-heavy.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-heavy.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-heavy.svg#gilroy-heavy') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 800;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../fonts/gilroy-bold.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-bold.svg#gilroy-bold') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('../fonts/gilroy-semibold.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-semibold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-semibold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-semibold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-semibold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-semibold.svg#gilroy-semibold') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../fonts/gilroy-medium.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-medium.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-medium.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-medium.svg#gilroy-medium') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../fonts/gilroy-regular.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-regular.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-regular.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-regular.svg#gilroy-regular') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy-Thin';
  src: url('../fonts/gilroy-thin.eot') ; /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-thin.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-thin.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-thin.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-thin.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-thin.svg#gilroy-thin') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 100;
}

@font-face {
  font-family: 'Gilroy-UltraLight';
  src: url('../fonts/gilroy-ultralight.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/gilroy-ultralight.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('../fonts/gilroy-ultralight.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gilroy-ultralight.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../fonts/gilroy-ultralight.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gilroy-ultralight.svg#gilroy-ultralight') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 300;
}
